<template>
  <!-- 数据统计 - 运营数据汇总 -->
  <div class="pageContol listWrap templateList faceStudentstatistics">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">运营数据汇总</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox">
            <div title="统计日期" class="searchboxItem">
              <span class="itemLabel">统计日期:</span>
              <el-date-picker
                v-model="retrievalData.statisticsDate"
                size="small"
                type="date"
                placeholder="选择统计日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </div>
            <div title="培训类型" class="searchboxItem ci-full">
              <span class="itemLabel">培训类型:</span>
              <el-cascader
                :props="{
                  emitPath: false,
                  value: 'id',
                  label: 'label',
                  children: 'children',
                  checkStrictly: true,
                }"
                v-model="retrievalData.trainTypeId"
                size="small"
                clearable
                :options="trainTypeList"
                @change="handleTrainType"
              ></el-cascader>
            </div>
            <div class="df">
              <el-button
                type="primary"
                size="small"
                class="bgc-bv"
                round
                @click="getData()"
                >查询</el-button
              >
              <el-button
                type="primary"
                size="small"
                class="bgc-bv"
                round
                @click="seeContrastData"
                >查看对比数</el-button
              >
              <el-button
                type="primary"
                size="small"
                class="bgc-bv"
                round
                @click="exportListData"
                >导出</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
              ><el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                width="100px"
              />
              <el-table-column
                label="培训类型"
                align="left"
                show-overflow-tooltip
                prop="trainTypeName"
                minWidth="120"
              />
              <el-table-column
                label="区域"
                align="left"
                show-overflow-tooltip
                prop="userName"
                minWidth="120"
              ><template slot-scope="scope">
                  <span>{{ scope.row.provinceName}} {{ scope.row.cityName?'-':''}} {{ scope.row.cityName}}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="开班数量"
                align="left"
                show-overflow-tooltip
                prop="projectNum"
                minWidth="100"
              />
              <el-table-column
                label="完成人数"
                align="left"
                show-overflow-tooltip
                prop="completeNumAll"
                minWidth="100"
              />
              <el-table-column
                label="应收金额"
                align="left"
                show-overflow-tooltip
                prop="amountReceivable"
                minWidth="100"
              />
              <el-table-column
                label="实收金额"
                align="left"
                show-overflow-tooltip
                prop="actualReceivedFee"
                minWidth="100"
              />
              <el-table-column
                label="应付金额"
                align="left"
                show-overflow-tooltip
                prop="amountPayable"
                minWidth="100"
              />
              <el-table-column
                label="实付金额"
                align="left"
                show-overflow-tooltip
                prop="amountPayable"
                minWidth="100"
              />
              <el-table-column
                label="发送确认单数量"
                align="left"
                show-overflow-tooltip
                prop="confirmBackNum"
                minWidth="140"
              />
              <el-table-column
                label="返回结算单数量"
                align="left"
                show-overflow-tooltip
                prop="returnBillNum"
                minWidth="140"
              />
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "operate/faceStudentstatistics",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据集
      retrievalData: {
        // 统计日期
        statisticsDate: new Date(new Date().getTime()-24*60*60*1000).Format('yy-MM-dd'),
        // 培训类型id
        trainTypeId: "",
      },
      // 培训类型 - 下拉数据
      trainTypeList: [],
      // 统计日期 - 限制可选时间范围
      pickerOptions: {
        disabledDate(time) {
          return (
            time.getTime() + 24 * 60 * 60 * 1000 > Date.now() ||
            new Date("2020-01-01").getTime() - 24 * 60 * 60 * 1000 >
              time.getTime()
          );
        },
      },
    };
  },
  created() {
    this.getTraintype();
  },
  mounted() {},
  methods: {
    // 初始化获取列表
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      // 培训类型
      if (this.retrievalData.trainTypeId) {
        params.trainTypeId = this.retrievalData.trainTypeId;
      }
      // 统计日期
      if (this.retrievalData.statisticsDate) {
        params.statisticsDate = this.retrievalData.statisticsDate;
      }
      this.doFetch({
        url: "/biz/settlement/statistics/outcomes/page",
        params,
        pageNum,
      });
    },
    // 获取培训类型 - 下拉数据
    getTraintype() {
      this.$post("/sys/category/train-type/tree").then((res) => {
        if (res.status == "0") {
          this.trainTypeList = res.data;
        }
      });
    },
    // 查看对比数据
    seeContrastData() {
      this.$router.push({
        path: "/web/operate/statistics/yw_PerformanceStatistics_db",
        query: {
          statisticsDate: this.retrievalData.statisticsDate
        }
      });
    },
    // 导出
    exportListData() {
      const params = {};
      // 培训类型
      if (this.retrievalData.trainTypeId) {
        params.trainTypeId = this.retrievalData.trainTypeId;
      }
      // 统计日期
      if (this.retrievalData.statisticsDate) {
        params.statisticsDate = this.retrievalData.statisticsDate;
      }
      this.$post(
        "/biz/settlement/statistics/outcomes/export",
        params
      ).then((res) => {
        if (res.status == "0") {
          window.open(res.data);
        }
      });
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less">
.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
.faceStudentstatistics {
  .operationControl {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .mabtm {
    padding-bottom: 0.675rem;
    border-bottom: 1px solid #f5f5f6;
  }
  .searchboxItem {
    display: flex;
    flex-direction: row;
    align-items: center;

    .el-cascader {
      height: 32px !important;
    }
  }
}
.searchBox {
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
</style>